import Vue from 'vue';
import { Mutation } from 'vuex';

import { State, DevicesOEE } from './model';
import { SET_MONITOR_VIEW_DEVICES_OEE } from './mutation-types';

/**
 * Set OEE data for multiple devices, representing the response from the
 * OEE service for filtered devices.
 */
const setDevicesOEE: Mutation<State> = (state, payload: DevicesOEE) => {
  // Ensure we always set a valid object, even if payload is null/undefined
  const oeeData = payload ?? {};
  Vue.set(state, 'devicesOEE', oeeData);
};

export default {
  [SET_MONITOR_VIEW_DEVICES_OEE]: setDevicesOEE,
};
