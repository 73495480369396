import type { Action } from 'vuex';

import type { RequiredDateTimeRange } from '@/types/app';
import type { RootState } from '@/store/state';
import type { State } from './model';

import { fetchOEE, fetchDeviceOEEMetrics } from '@/services/oee';

/**
 * OEE module actions.
 * Note: These actions fetch data but do not store it in state.
 * Consumers should handle the returned data directly.
 */

interface FetchDevicesOEEParams {
  /** Array of device IDs to fetch OEE data for */
  devices: string[];
  /**
   * Time range for the OEE calculation. Required unless `inProgress` is set.
   * When required, both `from` and `to` must be provided.
   */
  timeRange?: RequiredDateTimeRange;
  /**
   * Fetch in-progress entries. When set, the `timeRange` parameter is ignored
   * and all in-progress production entries are considered.
   */
  inProgress?: boolean;
}

interface FetchDeviceOEEParams {
  /** Single Device ID to fetch OEE data for */
  deviceId: string;
  timeRange: RequiredDateTimeRange;
}

/**
 * Fetch OEE metrics for a single device.
 * Returns raw OEE data without storing in state. Only returns entries with
 * calculated OEE values.
 */
const fetchDeviceOEE: Action<State, RootState> = async (
  { commit },
  { deviceId, timeRange }: FetchDeviceOEEParams
) => {
  const oee = await fetchDeviceOEEMetrics(deviceId, timeRange);
  return oee;
};

/**
 * Fetch OEE metrics for multiple devices. Returns raw OEE data without storing
 * in state. Only returns entries with calculated OEE values.
 */
const fetchDevicesOEE: Action<State, RootState> = async (
  { commit },
  { devices, timeRange, inProgress }: FetchDevicesOEEParams
) => {
  const response = await fetchOEE({
    devices,
    ...(timeRange && { from: timeRange.from, to: timeRange.to }),
    inProgress,
  });
  return response;
};

export default {
  fetchDeviceOEE,
  fetchDevicesOEE,
};
