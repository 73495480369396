import actions from './actions';
import { initialState } from './model';

/**
 * OEE module
 *
 * @description
 * This module is responsible for state and actions related to OEE.
 * At present, it only contains actions for fetching OEE data. Nothing is
 * stored in the state.
 */
export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {},
  actions,
};
