import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { initialState } from './model';

/**
 * Monitor view module
 *
 * @description
 * This module manages the state and operations for the OEE monitor view.
 *
 * Compare: the `oee` vuex module is stateless.
 * @see {@link ../oee/index}
 */
export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
