/**
 * State definition for the OEE module.
 * Currently empty as the module only contains actions for fetching OEE data.
 * Will be expanded as state management needs arise.
 */
export type State = Record<string, never>;

export const initialState: State = {};

export default initialState;
