import type { OEEResponse } from '@/types/domain/oee';

export type DevicesOEE = OEEResponse;

export interface State {
  devicesOEE: DevicesOEE;
}

export const initialState: State = {
  devicesOEE: {},
};

export default initialState;
