import type { Action } from 'vuex';

import type { RequiredDateTimeRange } from '@/types/app';
import type { RootState } from '@/store/state';
import type { State } from './model';

import { fetchOEE } from '@/services/oee';

import { SET_MONITOR_VIEW_DEVICES_OEE } from './mutation-types';

/**
 * These actions manage the OEE data displayed in the Monitor view.
 */

interface GetDevicesOEEParams {
  /** Array of device IDs to fetch OEE data for */
  devices: string[];
  timeRange: RequiredDateTimeRange;
}

/**
 * Get OEE data for multiple devices displayed in the Monitor view.
 */
const getDevicesOEE: Action<State, RootState> = async (
  { commit },
  { devices, timeRange }: GetDevicesOEEParams
) => {
  const response = await fetchOEE({
    devices,
    ...timeRange,
    version: 'v2',
  });
  commit(SET_MONITOR_VIEW_DEVICES_OEE, response);
  return response;
};

export default {
  getDevicesOEE,
};
