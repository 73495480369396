import type { Module } from 'vuex';

import anomaly from './anomaly';
import bills from './bills';
import comments from './comments';
import cyclesSettings from './cyclesSettings';
import dashboard from './dashboard';
import dashboardViews from './dashboardViews';
import deviceLabelledStates from './deviceLabelledStates';
import deviceSchedule from './deviceSchedule';
import deviceStates from './deviceStates';
import devicesLoadStates from './devicesLoadStates';
import devicesLists from './devicesLists';
import emailReports from './emailReports';
import exportData from './exportData';
import feeds from './feeds';
import filters from './filters';
import issues from './issues';
import lineIssueDetails from './lineIssueDetails';
import lineRules from './lineRules';
import maintenance from './maintenance';
import monitorView from './monitorView';
import notifprofiles from './notifprofiles';
import notificationBar from './notificationBar';
import oee from './oee';
import posts from './posts';
import production from './production';
import productionPeriod from './productionPeriod';
import scrap from './scrap';
import settings from './settings';
import shifts from './shifts';
import staff from './staff';
import tags from './tags';
import units from './units';
import urlShorter from './urlShorter';

// Using any for now to allow for gradual type improvements
const modules: Record<string, Module<any, any>> = {
  anomaly,
  bills,
  comments,
  cyclesSettings,
  dashboard,
  dashboardViews,
  deviceLabelledStates,
  deviceSchedule,
  deviceStates,
  devicesLoadStates,
  devicesLists,
  emailReports,
  exportData,
  feeds,
  filters,
  issues,
  lineIssueDetails,
  lineRules,
  maintenance,
  monitorView,
  notifprofiles,
  notificationBar,
  oee,
  posts,
  production,
  productionPeriod,
  scrap,
  settings,
  shifts,
  staff,
  tags,
  units,
  urlShorter,
};

export default modules;
