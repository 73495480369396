import axios from 'axios';

import config from '@/config';

import type { DateTimeRange } from '@/types/app';
import type { OEE, OEEResponse } from '@/types/domain/oee';

interface FetchOEEParams {
  /** Device IDs to fetch OEE for */
  devices: string[];
  /** Start time for the OEE calculation. Required unless inProgress is set. */
  from?: number;
  /** End time for the OEE calculation. Required unless inProgress is set. */
  to?: number;
  /** Fetch in-progress entries. When set, from and to are ignored. */
  inProgress?: boolean;
  /**
   * Specify the version of the OEE response desired.
   * v1: Only return entries that have calculated OEE values. This is the default.
   * v2: Return all entries. `oee` object may contain null values.
   *
   * This version shimming should only be in place temporarily. Next steps:
   * - Get API changes landed
   * - Decide how the scoreboard (other consumer of this API) should work: do
   *   we want to show more data there or keep it like it is now?
   * Timeframe: 2-4 weeks from now (2025-03-17)
   */
  version?: 'v1' | 'v2';
}

/**
 * Fetch raw OEE data from the API
 *
 * @throws Error if the request fails or returns invalid data
 * @throws Error if timeRange is missing when inProgress is not set
 */
export const fetchOEE = async (
  params: FetchOEEParams
): Promise<OEEResponse> => {
  const url = config.dataProxyUrls?.v2?.devices?.getOEE();

  if (!url) {
    throw new Error('[OEE Service] No OEE URL found');
  }

  // Validate that timeRange is provided when inProgress is not set
  if (
    !params.inProgress &&
    (params.from === undefined || params.to === undefined)
  ) {
    throw new Error(
      'Time range (from and to) is required when not fetching in-progress entries'
    );
  }

  try {
    // Strip version from params before making the API call
    const { version = 'v1', ...apiParams } = params;
    const { data, status, statusText } = await axios.get<{
      response: OEEResponse;
    }>(url, {
      params: apiParams,
    });

    if (data?.response) {
      // For v1, filter out entries with null/missing OEE values
      if (version === 'v1') {
        const filteredResponse: OEEResponse = {};
        Object.keys(data.response).forEach((deviceId) => {
          const oeeCalculation = data.response[deviceId].oee?.oee;
          if (oeeCalculation != null) {
            filteredResponse[deviceId] = data.response[deviceId];
          }
        });
        return filteredResponse;
      }
      return data.response;
    }

    throw new Error(
      `Invalid OEE data response: status=${status}, statusText=${statusText || 'none'}`
    );
  } catch (error) {
    // Rethrow with more context
    // TODO: Directly copied from store action -- is this the right handling?
    throw new Error(
      `Failed to fetch OEE data: ${error instanceof Error ? error.message : 'Unknown error'}`,
      { cause: error }
    );
  }
};

/**
 * Get OEE data for a device by device ID and time range
 *
 * @throws Error on fetch failure -- consumers should handle this
 * @returns The OEE metrics for the device, or null if not found
 */
export const fetchDeviceOEEMetrics = async (
  deviceId: string,
  timeRange: DateTimeRange
): Promise<OEE | null> => {
  if (typeof timeRange.from !== 'number' || typeof timeRange.to !== 'number') {
    throw new Error('Invalid timeRange: from and to must be numbers');
  }

  const data = await fetchOEE({
    from: timeRange.from,
    to: timeRange.to,
    devices: [deviceId],
  });

  return data?.[deviceId]?.oee ?? null;
};
