import type { OEE } from '@/types/domain/oee';

import { User } from '@/store/modules/users';
import { Uptime } from '../uptime';
import { Comment } from '../comments';
import { Scrap } from '../scrap/model';

export type ProductionPeriodStatus =
  | 'in-progress'
  | 'ready-for-review'
  | 'completed';

export interface ProductionPeriodDetails {
  batch?: string | null;
  comments?: string | null;
  createdByCsvUpload?: boolean;
}

export interface ProducedValues {
  produced: number | null;
  target: number | null;
  unitid: number | null;
  target_speed?: number | null;
  target_percentage?: number | null;
}

export interface WastedValues {
  wasted: number | null;
  target?: number | null;
  target_speed?: number | null;
  target_percentage?: number | null;
  unit?: any;
}

export interface ProductionPeriod {
  entryId?: string | null;
  id?: string;
  company_id?: string;
  created_at?: string;
  updated_at?: string;
  details: ProductionPeriodDetails;

  device_id: string;
  sku: number;
  timestamp?: number;
  duration: {
    production: { from: number; to: number };
    scheduled: { from: number; to: number };
    plannedDowntime: { from: number; unit: string; duration: number };
    runtime: number | null;
    uptime: number | null;
  };
  energy: number | null;
  oee: OEE | null;
  production: ProducedValues & {
    secondary?: ProducedValues;
  };
  waste: WastedValues & {
    secondary?: WastedValues;
  };
  uptime?: Uptime;
  comments?: Array<Comment>;
  changelog: Array<Changelog>;
  tags: any;
  people: { [key: string]: string | string[] | null };
  /**
   * Display unit for the production period. This is a derived field that is populated during the
   * mapping process:
   *
   * 1. The backend stores the raw unitid in production.unitid (see ProducedValues interface).
   *    This isn't currently used for anything but display purposes so the backend doesn't do much
   *     with it.
   * 2. During frontend data mapping (in actions.ts mapPeriods function):
   *    - The unitid is used to look up the full unit data via units/getUnitById
   *    - For sensor-based metrics, it may use numeratorUnit from the sensor config instead
   *    - The resulting unit object is attached here for display purposes
   *
   * This separation allows the frontend to:
   * - Display friendly unit names while keeping raw IDs in the database
   * - Override units for special cases (like sensor metrics) without changing the stored data
   * - Swap units for display without affecting the underlying data
   */
  unit?: { id: number; unitName: string };
  alerts?: {
    who: Array<User>;
    when: string;
    duration: string;
    unit: string;
    active: boolean;
    devicenickname: string;
  };
  cycles_info?: {
    info?: {
      completedBlocks: number;
      isSummaryCompleted: boolean;
      pendingBlocks: number;
      totalBlocks: number;
    };
    settings?: {
      companySettings: any;
      deviceSettings: any;
      settings: any;
      skuSettings: any;
    };
    summary?: {
      finalized: boolean;
      avgTime: number;
      avgTimeState: number;
      count: number;
      finalizedHours: number;
      lastSecondProcessed: number;
      maxTime: number;
      minTime: number;
      processedHours: number;
      sumTime: number;
      totalUnits: number;
    };
    summaryBlocks?: Array<{
      finalized: boolean;
      avgTime: number;
      avgTimeState: number;
      count: number;
      finalizedHours: number;
      lastSecondProcessed: number;
      maxTime: number;
      minTime: number;
      processedHours: number;
      sumTime: number;
    }>;
  };
  cycles_info_finalized?: boolean;
  calculated_qty?: number;
}

export interface Changelog {
  data: Array<any>;
  user: string;
  action: 'I' | 'U' | 'D';
  timestamp?: number;
}

export interface ParsedCsvRow {
  machine: {
    value: Object;
    error: string | null;
  };
  startTime: { value: string; error: string };
  endTime: { value: string; error: string };
  plannedDowntime: { value: number; error: string };
  product: { value: string; error: string };
  batch: { value: string; error: string };
  productionQuantity: { value: number; error: string };
  productionTarget: { value: number; error: string };
  wasteQuantity: { value: number; error: string };
  wasteTarget: { value: number; error: string };
  comments: { value: string; error: string };
}

export type ProductionPeriodCreateUpdateModalState =
  | {
      mode: 'HIDDEN';
    }
  | {
      mode: 'CREATE';
    }
  | {
      mode: 'UPDATE';
      period: ProductionPeriod;
    };

export type Sku = {
  id: number;
  companyId: string;
  deleted_at: string | null;
  skuCode: string;
  /**
   * A: Active
   * D: Disabled
   */
  status: 'A' | 'D';
};

export type DerivedProductionWasteValue = {
  value: any;
  isEstimated: boolean;
  source: string;
  estimatedValue?: {
    value: number | null;
    isEstimated: boolean;
    source: string;
  };
};

export interface State {
  error: Boolean;
  periods: {
    inProgress: {
      loading: boolean;
      error?: boolean | string;
      lastUpdate?: number;
      data: Array<ProductionPeriod>;
      updatedData: Array<ProductionPeriod>;
    };
    readyForReview: Array<ProductionPeriod>;
    completed: Array<ProductionPeriod>;
    others: Array<ProductionPeriod>;
    all: Array<ProductionPeriod>;
  };
  updatingOEEs: boolean;
  unSavedEntries: boolean;
  parsedCsv: Array<ParsedCsvRow>;
  csvParsed: Boolean;
  parsedCsvDataSaved: Boolean;
  savingCsvData: Boolean;
  csvSaveMessage: string;
  unSavedCsvRows: Array<number>;
  csvMapping: Object;
  throughputModels: Object;
  editingScrap: Scrap | 'NEW' | 'NONE';
  productionPeriodCreateUpdateModal: ProductionPeriodCreateUpdateModalState;
}

export const initialState: State = {
  error: false,
  periods: {
    inProgress: {
      loading: false,
      data: [],
      updatedData: [],
    },
    readyForReview: [],
    completed: [],
    others: [],
    all: [],
  },
  parsedCsv: [],
  csvParsed: false,
  parsedCsvDataSaved: false,
  savingCsvData: false,
  csvSaveMessage: '',
  unSavedCsvRows: [],
  updatingOEEs: false,
  unSavedEntries: false,
  csvMapping: {},
  throughputModels: {},
  editingScrap: 'NONE',
  productionPeriodCreateUpdateModal: {
    mode: 'HIDDEN',
  },
};

export default initialState;
