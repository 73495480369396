import { State } from './model';
import type { OEE } from '@/types/domain/oee';

export default {
  /**
   * Get all OEE data
   */
  getDevicesOEE: (state: State) => state.devicesOEE,

  /**
   * Get OEE data for a specific device
   * @param deviceId - The ID of the device to get OEE data for
   * @returns The OEE data for the device, or undefined if the device doesn't
   * have any OEE data in the store
   */
  getOEEByDeviceId:
    (state: State) =>
    (deviceId: string): OEE | undefined =>
      state.devicesOEE[deviceId] ? state.devicesOEE[deviceId].oee : undefined,
};
